import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  loading: false,
  error: null,
  selectedCategory: null,
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setCategories, setSelectedCategory, setLoading, setError } = categorySlice.actions;
export default categorySlice.reducer;
export const selectCategories = (state) => state.categories.categories;
export const selectLoading = (state) => state.categories.loading;
export const selectError = (state) => state.categories.error;