import React from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';

const StarRating = ({ rating }) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<FaStar key={i} className="text-primary w-4 h-4" />);
        } else {
            stars.push(<FaRegStar key={i} className="text-secondary2 w-4 h-4" />);
        }
    }

    return <div className="flex ">{stars}</div>;
};

export default StarRating;