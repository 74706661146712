"use client";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from 'next/navigation';
import Link from "next/link";
import { categoriesData } from '@/Data';
import { selectIsLoggedIn } from "@/reducers/AuthReducers";
import { selectCategories, selectLoading } from "@/reducers/CategoryReducers";
import { FaShop } from "react-icons/fa6";
import { MdHelp } from "react-icons/md";
import { selectCart } from "@/reducers/cartReducers";
import { getIconComponent } from "@/lib/icons";
import TopNavbar from "./TopNabar";
import BottomNavbar from "./BottomNavbar";




export default function Navbar() {
    const [openToggle, setOpenToggle] = useState(false);
    const [showBottomSection, setShowBottomSection] = useState(true);
    const categories = useSelector(selectCategories)
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const loading = useSelector(selectLoading)
    const cartData = useSelector(selectCart)

    const dispatch = useDispatch();
    const router = useRouter();

    // const getSuggestions = (value) => {
    //     const inputValue = value.trim().toLowerCase();
    //     const inputLength = inputValue.length;

    //     return inputLength === 0 ? [] : suggestionsData.filter(sug =>
    //         sug.name.toLowerCase().includes(inputValue)
    //     );
    // };



    const handleToggle = () => {
        setOpenToggle(!openToggle);
    };

    function shouldRenderSuggestions(value, reason) {
        return value.trim().length >= 0;
    }

    useEffect(() => {
        const handleScroll = () => {
            setShowBottomSection(window.scrollY <= 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);







    //   const handleLogout = () => {
    //     dispatch(logOut());
    //   };



    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    //   const fetchData = useCallback(async () => {
    //     try {
    //       const res = await filterProductMutation();
    //       if (res.data) {
    //         dispatch(setFilterProducts(res.data));
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }, [filterProductMutation]);

    //   useEffect(() => {
    //     fetchData();
    //   }, [fetchData]);





    const inputProps = {
        placeholder: "Search anything ...",
        className: "bg-white border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2 ",
        value,
        onChange: (e) => {
            const newValue = e.target.value;
            setValue(newValue);
            // setSuggestions(getSuggestions(newValue));
        },
    };






    return (

        <div className={` fixed w-full min-h-[60px]   z-50 font-[sans-serif]`}>
            <header className="">
                {showBottomSection &&
                    <div className="w-full lg:py-2 bg-blue-950">
                        <TopNavbar openToggle={openToggle} handleToggle={handleToggle} />
                    </div>
                }

                <div className="w-full bg-gray-50 border-b">
                    <BottomNavbar />
                </div>


            </header >
        </div >

    );
}