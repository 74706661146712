// src/hooks/fetchAllProducts.js
// Function to fetch all product data
export async function fetchAllProducts() {
    try {
        const response = await fetch(`https://testbackend.cinab.co.ke/v1/products`); // Replace with your actual API URL
        if (!response.ok) {
            console.log("Error fetching products");
            return null; // Handle errors as needed
        }
        const products = await response.json(); // Parse JSON data

        // Generate metadata for each product
        return products
    } catch (error) {
        console.error("Error fetching all product data:", error);
        return null; // Handle error and return null
    }
}
