import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    product: {},
    loading: false,
    error: null,
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    increaseQuantity: (state) => {
      if (state.product.quantity != null) {
        state.product.quantity += 1;
      }
    },
    decreaseQuantity: (state) => {
      if (state.product.quantity != null && state.product.quantity > 0) {
        state.product.quantity -= 1;
      }
    },
  },
});

export const {
  setProducts,
  setProduct,
  setLoading,
  setError,
  increaseQuantity,
  decreaseQuantity,
} = productsSlice.actions;

export default productsSlice.reducer;

export const selectProducts = (state) => state.products.products;
export const selectProduct = (state) => state.products.product;
export const selectOutOfStockProducts = (state) =>
  state.products.products.filter((product) => product.quantity === 0);
export const selectLoading = (state) => state.products.loading;
export const selectError = (state) => state.products.error;
