'use client';

import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Email is required');
      setSuccess('');
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Invalid email address');
      setSuccess('');
      return;
    }

    setError('');
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess('Subscription successful!');
        setEmail('');
      } else {
        setError('Subscription failed. Try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <footer className="bg-blue-950 text-white w-full mt-4">
    <main>
    <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#ffffff19] py-4 mt-2">
        <h1 className="lg:text-3xl text-2xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
          <h3 className="text-teal-400 ">Subscribe to our newsletter</h3>
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Your Email"
              className="text-gray-800 sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
            />
            <button
              type="submit"
              className="bg-teal-400 hover:bg-teal-500 duration-300 px-5 py-2.5 font-[Poppins] rounded-md text-white md:w-auto w-full"
            >
              Subscribe
            </button>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
          {success && <p className="text-green-500 mt-2">{success}</p>}
        </form>
      </div>

      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        
 {/* Contact Us */}
<div className="text-center md:text-left flex flex-col justify-center ml-4">
  <h3 className="text-2xl font-bold mb-4">Contact Us</h3>

  {/* Address */}
  <div className="mb-4">
    <p className="mb-1">Address: Ibgaro 1 Basement Room B4</p>
    <p>First Avenue, Eastleigh Nairobi</p>
  </div>

  {/* Phone and Email */}
  <div className="mb-4">
    <p className="mb-1">Phone: +123 456 789</p>
    <p>Email: info@company.com</p>
  </div>

  {/* Operating Hours */}
  <div>
    <p className="mb-1">Mon-Fri: 9:00 AM - 2:00 PM</p>
    <p>Saturday: 8:00 AM - 4:00 PM</p>
  </div>
</div>

        {/* Quick Links */}
        <div className="text-center md:text-left flex flex-col">
          <h3 className="font-bold mb-4 text-2xl text-center md:text-left">Company</h3>
          <ul className="space-y-2">
            <li><a href="#about-us" className="hover:underline">About Us</a></li>
            <li><a href="#faq" className="hover:underline">FAQ</a></li>
            <li><a href="#privacy-policy" className="hover:underline">Privacy Policy</a></li>
            <li><a href="#terms-and-conditions" className="hover:underline">Terms and Conditions</a></li>
          </ul>
        </div>

        {/* Follow Us & App Download */}
        <div>
          <h4 className="font-bold mb-4 text-2xl text-center md:text-left">Follow Us</h4>
          <div className="flex justify-center md:justify-start space-x-6">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:scale-110 transition-transform duration-300"
            >
              <FaFacebook size={40} />
            </a>
            <a
              href="https://x.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:scale-110 transition-transform duration-300"
            >
              <FaTwitter size={40} />
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-red-600 hover:scale-110 transition-transform duration-300"
            >
              <FaYoutube size={40} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:scale-110 transition-transform duration-300"
            >
              <FaLinkedin size={40} />
            </a>
          </div>

          {/* App Store and Google Play */}
          <h4 className="font-bold mb-4 text-lg text-center md:text-left mt-6">Download Our App</h4>
          <div className="flex flex-col items-center md:flex-row md:items-start space-x-0 md:space-x-4 mt-4">
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-105 transform transition duration-300 ease-in-out mb-4 md:mb-0"
            >
              <img src="/assets/images/googleplay.png" alt="Google Play" className="w-32 md:w-24" />
            </a>
            <a
              href="https://www.apple.com/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-105 transform transition duration-300 ease-in-out"
            >
              <img src="/assets/images/appstore.png" alt="App Store" className="w-32 md:w-24" />
            </a>
          </div>
        </div>
      </div>
      
      <hr className="my-4 border-gray-600" />
      <p className="text-center mb-4">&copy; Cinab 2024. All rights reserved.</p>
    </main>
    </footer>
  );
};

export default Footer;