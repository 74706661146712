import React from 'react';
import * as Icons from 'react-icons/fa';


export const getIconComponent = (iconName) => {
    if (Icons[iconName]) {
        return React.createElement(Icons[iconName]);
    }
    console.log(`Icon ${iconName} not found`);
    // Log if icon not found
    return null; // Return null if the icon is not found
};
