"use client";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fuse from 'fuse.js'; // Ensure you have Fuse.js imported
import Link from "next/link";
import { logOut, selectCurrentUser, selectIsLoggedIn } from "@/reducers/AuthReducers";
import { selectCart } from "@/reducers/cartReducers";
import StarRating from "../reusable/StarRating";
import { selectProducts, setProducts } from "@/reducers/productReducers";
import { fetchAllProducts } from "@/hooks/fetchAllProducts";
import { RiLogoutCircleRLine } from 'react-icons/ri';
import generateMetadata from "@/hooks/generateMetadataAllProducts";
import { useRouter } from "next/navigation";

export const metadata = generateMetadata(); // Corrected export statement

export default function BottomNavbar() {
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const cartData = useSelector(selectCart);
    const [loading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser)
    const products = useSelector(selectProducts);
    const router = useRouter(); // Initialize router
    console.log(isLoggedIn);
    const handleLogout = () => {
        dispatch(logOut())
    }


    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const productData = await fetchAllProducts();
                if (productData) {
                    dispatch(setProducts(productData));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, [dispatch]);





    const getSuggestions = (inputValue) => {
        const trimmedValue = inputValue.trim().toLowerCase();
        const inputLength = trimmedValue.length;

        if (inputLength === 0) return [];

        // Dynamically create keys based on the product structure
        const keys = Object.keys(products[0] || {}).reduce((acc, key) => {
            if (products[0] && products[0][key] && typeof products[0][key] === 'object') {
                Object.keys(products[0][key]).forEach(nestedKey => {
                    acc.push(`${key}.${nestedKey}`);
                });
            } else if (products[0] && products[0][key] !== null) {
                acc.push(key);
            }
            return acc;
        }, []);

        // Create a new Fuse instance
        const fuse = new Fuse(products, {
            keys, // Use the dynamically created keys
            includeScore: true, // Include the score to determine the relevance of the match
            threshold: 0.3, // Adjust this threshold for sensitivity
        });

        // Perform the search and extract products
        return fuse.search(trimmedValue).map(item => item.item);
    };



    const inputProps = {
        placeholder: "Search by brands categories product ...",
        className: "bg-white border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-gray-500 focus:border-gray-500 block w-full p-2",
        value,
        onChange: (e) => {
            const newValue = e.target.value;
            setValue(newValue);
            setSuggestions(getSuggestions(newValue));
        },
    };

    const handleViewAll = () => {
        try {
            // Use a string URL format to ensure compatibility
            router.push(`/searchQuery?term=${encodeURIComponent(value)}`);
            setValue(""); // Clear the input value
            setSuggestions([]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const handleViewOne = async (term) => {
        setSuggestions([]);
        setValue("");
        await router.push(`/products/?slug=${term}`);
    };
    return (
        <div className="flex flex-wrap items-center mx-auto max-w-[1440px] justify-between py-1.5 sm:px-10   gap-x-4">
            <div className="w-3/12 hidden lg:flex ">
                <Link href="/" className="flex items-center space-x-2">
                    <img
                        src="/assets/images/logo.ico"
                        alt="logo"
                    // className="w- h-10"
                    />

                </Link>

                <h1 className="text-blue-900 font-semibold mt-1 ms-1 text-lg">CINAB</h1>



            </div>
            <div className="flex-1 relative ">
                <input {...inputProps} />


                {suggestions.length > 0 && (
                    <div className="absolute z-10  block  bg-white border border-gray-200 rounded-lg dark:hover:bg-gray-700 w-full">

                        {loading ? (
                            <div>
                                <h1>Loading....</h1>
                            </div>
                        ) : (
                            <>
                                {suggestions.length > 0 && (
                                    <>
                                        {suggestions.slice(0, 6).map(suggestion => ( // Show a minimum of three suggestions
                                            <div key={suggestion.id} className="p-2 border-b hover:bg-gray-100 cursor-pointer" onClick={() => {
                                                handleViewOne(suggestion?.slug);
                                            }}>
                                                <div className="flex gap-x-2">
                                                    <img src={suggestion.image} alt={suggestion.name} className="h-12 w-16 rounded-lg object-cover" />
                                                    <div>
                                                        <span className="font-medium">{suggestion.name}</span>
                                                        <StarRating rating={suggestion.rating} />
                                                        <div className="text-sm text-secondary1 font-semibold">
                                                            {suggestion.basePrice}
                                                            <span className="line-through opacity-75 text-gray-600">{suggestion.basePreviousPrice}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {suggestions.length > 6 && ( // Show "View All" if more than three suggestions
                                            <button onClick={handleViewAll} aria-label="view-all" type="" className="text-center border-t-2 py-4 bg-yellow-400 w-full hover:cursor-pointer hover:bg-yellow-300 border-yellow-300">
                                                <span type="button" aria-label="View All" className="text-white text-lg">
                                                    View All
                                                </span>
                                            </button>
                                        )}


                                    </>
                                )}

                            </>
                        )}

                    </div>
                )}

            </div>
            <div className="w-3/12 justify-end lg:flex hidden">
                <div className='flex items-center max-sm:ml-auto space-x-6'>
                    <ul className="flex space-x-4">
                        <li className='max-sm:hidden flex text-[15px] max-lg:py-2 px-3 font-medium text-[#333] cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" className="mr-2" viewBox="0 0 24 24">
                                <g data-name="Layer 2">
                                    <path
                                        d="M14.5 12.75A3.22 3.22 0 0 1 12 11.6a3.27 3.27 0 0 1-2.5 1.15A3.22 3.22 0 0 1 7 11.6a2.91 2.91 0 0 1-.3.31 3.22 3.22 0 0 1-2.51.82 3.35 3.35 0 0 1-2.94-3.37v-.71a4.76 4.76 0 0 1 .24-1.5l1.57-4.7a1.75 1.75 0 0 1 1.66-1.2h14.56a1.75 1.75 0 0 1 1.66 1.2l1.57 4.7a4.76 4.76 0 0 1 .24 1.5v.71a3.35 3.35 0 0 1-2.92 3.37 3.2 3.2 0 0 1-2.51-.82c-.11-.1-.22-.22-.32-.33a3.28 3.28 0 0 1-2.5 1.17zm-9.78-10a.26.26 0 0 0-.24.17l-1.56 4.7a3.27 3.27 0 0 0-.17 1v.71a1.84 1.84 0 0 0 1.57 1.88A1.75 1.75 0 0 0 6.25 9.5a.75.75 0 0 1 1.5 0 1.67 1.67 0 0 0 1.75 1.75 1.76 1.76 0 0 0 1.75-1.75.75.75 0 0 1 1.5 0 1.67 1.67 0 0 0 1.75 1.75 1.76 1.76 0 0 0 1.75-1.75.75.75 0 0 1 1.5 0 1.75 1.75 0 0 0 1.93 1.74 1.84 1.84 0 0 0 1.57-1.88v-.71a3.27 3.27 0 0 0-.17-1l-1.56-4.7a.26.26 0 0 0-.24-.17z"
                                        data-original="#000000" />
                                    <path
                                        d="M20 22.75H4A1.76 1.76 0 0 1 2.25 21v-9.52a.75.75 0 0 1 1.5 0V21a.25.25 0 0 0 .25.25h16a.25.25 0 0 0 .25-.25v-9.53a.75.75 0 1 1 1.5 0V21A1.76 1.76 0 0 1 20 22.75z"
                                        data-original="#000000" />
                                    <path
                                        d="M15.5 22.75h-7a.76.76 0 0 1-.75-.75v-5a1.76 1.76 0 0 1 1.75-1.75h5A1.76 1.76 0 0 1 16.25 17v5a.76.76 0 0 1-.75.75zm-6.25-1.5h5.5V17a.25.25 0 0 0-.25-.25h-5a.25.25 0 0 0-.25.25z"
                                        data-original="#000000" />
                                </g>
                            </svg>
                            Help
                        </li>
                        <li className="  max-lg:py-2 px-3 group relative">
                            <span
                                className="hover:text-blue-600 block font-semibold transition-all"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    className="cursor-pointer fill-black"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M437.02 74.981C388.667 26.629 324.38 0 256 0S123.333 26.629 74.98 74.981C26.629 123.333 0 187.62 0 256s26.629 132.667 74.98 181.019C123.333 485.371 187.62 512 256 512s132.667-26.629 181.02-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.667-74.98-181.019zM256 482c-66.869 0-127.037-29.202-168.452-75.511C113.223 338.422 178.948 290 256 290c-49.706 0-90-40.294-90-90s40.294-90 90-90 90 40.294 90 90-40.294 90-90 90c77.052 0 142.777 48.422 168.452 116.489C383.037 452.798 322.869 482 256 482z"
                                        data-original="#000000"
                                    />
                                </svg>
                            </span>
                            <ul className="absolute shadow-lg bg-white space-y-3 top-10 right-0 sm:min-w-[320px] max-sm:min-w-[250px] z-50 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[700px] px-6 group-hover:pb-4 group-hover:pt-6 transition-all duration-500">
                                <li>
                                    <div className="flex flex-col items-start">
                                        <h6 className="font-semibold text-[15px]">Happy Shopping</h6>
                                        {isLoggedIn ? (
                                            <span className="text-lg text-gray-500 mt-1">{currentUser.firstName} {currentUser.lastName}</span>
                                        ) : (
                                            <span className="text-sm text-gray-500 mt-1">To access account and manage orders</span>
                                        )}

                                        {isLoggedIn ? (
                                            <button
                                                type="button"
                                                onClick={handleLogout}
                                                aria-label="Logout"
                                                className="bg-transparent hover:cursor-pointer border-2 border-transparent   py-1.5 mt-4 text-sm text-black font-semibold flex items-center space-x-2"
                                            >
                                                <RiLogoutCircleRLine className="text-lg" />
                                                <span>Logout</span>
                                            </button>
                                        ) : (
                                            <Link
                                                href={"/login"}
                                                className="bg-transparent border-2 border-gray-300 hover:border-black rounded px-4 py-2.5 mt-4 text-sm text-black font-semibold"
                                            >
                                                LOGIN / SIGNUP
                                            </Link>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <hr className="border-b-0 my-4" />
                                </li>
                                <li>
                                    <ul className="space-y-1.5 text-start">
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Orders</span>
                                            ) : (
                                                <Link href="/orders" className="text-sm text-gray-500 hover:text-black">Orders</Link>
                                            )}
                                        </li>
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Wishlist</span>
                                            ) : (
                                                <Link href="/wishlist" className="text-sm text-gray-500 hover:text-black">Wishlist</Link>
                                            )}
                                        </li>
                                        {/* <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Gift card</span>
                                            ) : (
                                                <Link href="/gift-card" className="text-sm text-gray-500 hover:text-black">Gift card</Link>
                                            )}
                                        </li> */}
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Stima Sacco Form us</span>
                                            ) : (
                                                <Link href="/https://www.stima-sacco.com/download/make-over-loan-application-form/?wpdmdl=20244&refresh=6620e5e14d1ab1713432033" target="_blank" className="text-sm text-gray-500 hover:text-black">Stima Sacco Form us</Link>
                                            )}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <hr className="border-b-0 my-4" />
                                </li>
                                {/* <li>
                                    <ul className="space-y-1.5">
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">My profile</span>
                                            ) : (
                                                <Link href="/my-profile" className="text-sm text-gray-500 hover:text-black">My profile</Link>
                                            )}
                                        </li>
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Track order</span>
                                            ) : (
                                                <Link href="/track-order" className="text-sm text-gray-500 hover:text-black">Track order</Link>
                                            )}
                                        </li>
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Account</span>
                                            ) : (
                                                <Link href="/account" className="text-sm text-gray-500 hover:text-black">Account</Link>
                                            )}
                                        </li>
                                        <li>
                                            {!isLoggedIn ? (
                                                <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Manage notifications</span>
                                            ) : (
                                                <Link href="/manage-notifications" className="text-sm text-gray-500 hover:text-black">Manage notifications</Link>
                                            )}
                                        </li>
                                    </ul>
                                </li> */}
                            </ul>
                        </li>
                        <li className="relative px-1  lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300 hover:cursor-pointer" >
                            <Link href={"/cart"}>
                                <span className="relative">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        className="cursor-pointer fill-black inline-block"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"
                                            data-original="#000000"
                                        ></path>
                                    </svg>
                                    <span className="absolute left-auto -ml-1 -top-1 rounded-full bg-red-500 px-1 py-0 text-xs text-white" >

                                        {cartData && cartData === 0 ? 0 : cartData.length}
                                    </span>
                                </span>
                            </Link>
                        </li>
                    </ul>


                </div>
            </div>
        </div>

    );
}