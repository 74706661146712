"use client";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from 'next/navigation';
import Link from "next/link";
import { categoriesData } from '@/Data';
import { logOut, selectCurrentUser, selectIsLoggedIn } from "@/reducers/AuthReducers";
import { selectCategories, selectLoading } from "@/reducers/CategoryReducers";
import { FaShop } from "react-icons/fa6";
import { MdHelp } from "react-icons/md";
import { selectCart } from "@/reducers/cartReducers";
import { getIconComponent } from "@/lib/icons";


export default function TopNavbar({ openToggle, handleToggle, }) {
    const [showBottomSection, setShowBottomSection] = useState(true);
    const categories = useSelector(selectCategories)
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const loading = useSelector(selectLoading)
    const cartData = useSelector(selectCart)
    const currentUser = useSelector(selectCurrentUser)
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logOut())
    }
    return (

        <div className="flex-1 flex justify-between mx-auto max-w-[1280px]  gap-4 px-4">
            <div className="flex items-center">
                <Link href="/" className="flex items-center space-x-2">
                    <img
                        src="/assets/images/logo.ico"
                        alt="logo"
                    // className="w- h-10"
                    />

                </Link>

                <h1 className="text-white font-semibold mt-1 ms-1 ms-1 text-lg">CINAB</h1>


            </div>


            <div
                id="collapseMenu"
                className={`${openToggle ? "" : "hidden"} lg:!flex lg:items-center max-lg:before:fixed max-lg:before:bg-white max-lg:before:opacity-40 max-lg:before:inset-0 max-lg:before:z-50`}
            >
                <button
                    id="toggleClose"
                    onClick={handleToggle}
                    type="button"
                    aria-label="Toggle Navigation"
                    className="lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 fill-black"
                        viewBox="0 0 320.591 320.591"
                    >
                        <path
                            d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                            data-original="#000000"
                        ></path>
                        <path
                            d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                            data-original="#000000"
                        ></path>
                    </svg>
                    <span className="sr-only">Open</span>
                </button>

                <ul className="lg:!flex !text-start  gap-y-6 lg:gap-x-10 max-lg:space-y-3 max-lg:fixed max-lg:bg-blue-950 max-lg:w-2/3 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:px-10 max-lg:py-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">

                    <li className="mb-6 hidden max-lg:flex items-center">
                        <Link href="/" className="flex items-center space-x-2">
                            <img
                                src="/assets/images/logo.ico"
                                alt="logo"
                            />
                        </Link>
                        <h1 className="text-white font-semibold mt-1 ms-1 text-lg">CINAB</h1>
                    </li>
                    <li className=" max-lg:py-1 lg:hidden border-b relative lg:after:absolute lg:after:bg-white lg:after:w-full lg:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">
                        <Link href="/" className="text-white block text-[15px] font-medium">
                            My Account
                        </Link>
                    </li>

                    <li className=" max-lg:py-1 flex items-center space-x-2 relative ps-1 lg:after:absolute lg:after:bg-white lg:after:w-full lg:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">

                        <Link href="/" className="text-white items-center flex text-[15px] font-medium">
                            <span className="lg:hidden mr-2">{getIconComponent("FaHome")}</span>

                            <span>Home</span>
                            {/* Home */}
                        </Link>
                    </li>
                    <li className=" max-lg:py-1 flex items-center space-x-2 relative ps-1 lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">
                        <Link href="/catalog" className="text-white flex items-center text-[15px] font-medium">
                            <span className="lg:hidden mr-2">{getIconComponent("FaShopify")}</span>
                            <span>Shop</span>
                        </Link>
                    </li>

                    <li className=" max-lg:py-1 flex items-center space-x-2 relative ps-1 lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">
                        <Link target="_blank" href="https://sellercenter.cinab.co.ke/" className="text-white flex items-center  text-[15px] whitespace-nowrap font-medium">
                            <span className="lg:hidden mr-2"><FaShop /></span>
                            <span>Sell With Cinab</span>
                        </Link>
                    </li>
                    <li className=" max-lg:py-1 flex items-center space-x-2 relative ps-1 lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">
                        <Link target="_blank" href="https://www.stima-sacco.com/download/make-over-loan-application-form/?wpdmdl=20244&refresh=6620e5e14d1ab1713432033" className="text-white flex items-center  whitespace-nowrap text-[15px] font-medium">
                            <span className="lg:hidden mr-2">{getIconComponent("FaShopware")}</span>
                            <span>  Stima Sacco Form</span>
                        </Link>
                    </li>
                    <li className="lg:hidden  flex items-center space-x-2 max-lg:py-1 relative ps-1 lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">
                        <Link href="/help" className="text-white flex items-center text-[15px] font-medium">
                            <span className="lg:hidden mr-2"><MdHelp /></span>
                            <span> Help</span>
                        </Link>
                    </li>
                    <div className="border-b" />

                    {/* Categories visible only on mobile */}
                    <ul className="max-lg:block hidden">
                        <li className="text-gray-300 text-[16px] font-medium">
                            Our    Categories
                        </li>
                        {
                            loading ? (
                                Array.from({ length: 10 }).map((_, index) => (
                                    <li
                                        key={index}
                                        className="cursor-pointer animate-pulse flex items-center space-x-2 ps-1 hover:bg-gray-200 p-1 rounded-md"
                                    >
                                        <div className="bg-gray-300 w-6 h-6 rounded-full"></div>
                                        <div className="bg-gray-300 w-full h-4 rounded"></div>
                                    </li>
                                ))
                            ) : (
                                categories.map((category, index) => (
                                    <li key={index} className="text-white ps-1 text-[15px] font-medium">
                                        <li className="lg:hidden   max-lg:py-1 relative  lg:hover:after:absolute lg:after:bg-white lg:after:w-0 lg:hover:after:w-full lg:hover:after:h-[2px] lg:after:block lg:after:-bottom-4 lg:after:transition-all lg:after:duration-300">

                                            <Link href={`/${category?.name}`} className="text-white  text-[15px] font-medium flex items-center space-x-2">
                                                <span className="mr-2">{getIconComponent(category?.icon)}</span>
                                                {category?.name}
                                            </Link>
                                        </li>
                                    </li>
                                ))
                            )
                        }
                    </ul>
                </ul>

            </div>

            <div className="ml-auto block lg:hidden">
                <ul className='flex items-center'>
                    <li className='max-lg:py-2 px-3 cursor-pointer'>
                        <Link href={"/cart"}>
                            <span className="relative flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    className="inline fill-white"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"
                                        data-original="#000000"
                                    />
                                </svg>
                                <span className="absolute left-auto -ml-1 -top-1 rounded-full bg-red-500 px-1 py-0 text-xs text-white">
                                    {cartData && cartData === 0 ? 0 : cartData.length}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className='flex text-[15px] max-lg:py-2 px-3 hover:text-[#007bff] hover:fill-[#007bff]'>
                        <ul>
                            <li className="  max-lg:py-2 px-3 group relative">
                                <span className="hover:text-blue-600 block font-semibold transition-all">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        className="cursor-pointer fill-white"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M437.02 74.981C388.667 26.629 324.38 0 256 0S123.333 26.629 74.98 74.981C26.629 123.333 0 187.62 0 256s26.629 132.667 74.98 181.019C123.333 485.371 187.62 512 256 512s132.667-26.629 181.02-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.667-74.98-181.019zM256 482c-66.869 0-127.037-29.202-168.452-75.511C113.223 338.422 178.948 290 256 290c-49.706 0-90-40.294-90-90s40.294-90 90-90 90 40.294 90 90-40.294 90-90 90c77.052 0 142.777 48.422 168.452 116.489C383.037 452.798 322.869 482 256 482z"
                                            data-original="#000000"
                                        />
                                    </svg>
                                </span>

                                <ul className="absolute shadow-lg bg-white space-y-3 top-10 right-0 sm:min-w-[320px] max-sm:min-w-[250px] z-50 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[700px] px-6 group-hover:pb-4 group-hover:pt-6 transition-all duration-500">
                                    {/* Welcome Message */}
                                    <li>
                                        <div>
                                            <h6 className="font-semibold text-[15px]">Welcome</h6>
                                            {isLoggedIn ? (
                                                <span className="text-lg text-gray-500 mt-1">{currentUser.firstName} {currentUser.lastName}</span>
                                            ) : (
                                                <span className="text-sm text-gray-500 mt-1">To access account and manage orders</span>
                                            )}
                                        </div>
                                    </li>

                                    {/* Login/Logout Button */}
                                    <li>
                                        {isLoggedIn ? (
                                            <button
                                                type="button"
                                                onClick={handleLogout}
                                                aria-label="logout"
                                                className="bg-transparent border-2 border-gray-300 hover:border-black rounded px-4 py-2.5 mt-4 text-sm text-black font-semibold"
                                            >
                                                LOGOUT
                                            </button>
                                        ) : (
                                            <Link
                                                href="/login"
                                                className="bg-transparent border-2 border-gray-300 hover:border-black rounded px-4 py-2.5 mt-4 text-sm text-black font-semibold"
                                            >
                                                LOGIN / SIGNUP
                                            </Link>
                                        )}
                                    </li>

                                    {/* Separator */}
                                    <li>
                                        <hr className="border-b-0 my-4" />
                                    </li>

                                    {/* Navigation Links */}
                                    <li>
                                        <ul className="space-y-1.5">
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Order</span>
                                                ) : (
                                                    <Link href="/order" className="text-sm text-gray-500 hover:text-black">Order</Link>
                                                )}
                                            </li>
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Wishlist</span>
                                                ) : (
                                                    <Link href="/wishlist" className="text-sm text-gray-500 hover:text-black">Wishlist</Link>
                                                )}
                                            </li>
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Stima Sacco Form Us</span>
                                                ) : (
                                                    <Link href="/https://www.stima-sacco.com/download/make-over-loan-application-form/?wpdmdl=20244&refresh=6620e5e14d1ab1713432033" target="_blank"  className="text-sm text-gray-500 hover:text-black">Stima Sacco Form us</Link>
                                                )}
                                            </li>
                                        </ul>
                                    </li>

                                    {/* Separator */}
                                    <li>
                                        <hr className="border-b-0 my-4" />
                                    </li>

                                    {/* More Navigation Links */}
                                    {/* <li>
                                        <ul className="space-y-1.5">
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Coupons</span>
                                                ) : (
                                                    <Link href="/" className="text-sm text-gray-500 hover:text-black">Coupons</Link>
                                                )}
                                            </li>
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Saved Credits</span>
                                                ) : (
                                                    <Link href="/" className="text-sm text-gray-500 hover:text-black">Saved Credits</Link>
                                                )}
                                            </li>
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Help Center</span>
                                                ) : (
                                                    <Link href="/" className="text-sm text-gray-500 hover:text-black">Help Center</Link>
                                                )}
                                            </li>
                                            <li>
                                                {!isLoggedIn ? (
                                                    <span className="text-sm text-gray-500 opacity-50 cursor-not-allowed">Saved Addresses</span>
                                                ) : (
                                                    <Link href="/" className="text-sm text-gray-500 hover:text-black">Saved Addresses</Link>
                                                )}
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>

                            </li>
                        </ul>
                    </li>
                    <li id="toggleOpen" type="button" className='lg:hidden' onClick={handleToggle}>
                        <button aria-label="Toggle Navigation" type="button"
                        >
                            <svg className="w-7 h-7 fill-white" fill="#333" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>

        </div>

    );
}