export const deliveryMethods = [
  { id: 2, name: 'Door Delivery', description: "Delivery with 3-4 days within nairobi (Only Used within Nairbi)", pickupPoints: [] },
  {
    id: 3, name: 'Pick Up', description: 'Pick up from nearest location.',

  }
];


// dummyBrands.js

export const dummyBrands = [
  { id: 1, name: 'Mika' },
  { id: 2, name: 'Hotpoint' },
  { id: 2, name: 'Roto' },
  { id: 3, name: 'Xiaomi' },
  { id: 4, name: 'Samsung' },
  { id: 5, name: 'LG' },
  { id: 6, name: 'Vitron' },
  { id: 7, name: 'Nivea' },
  { id: 8, name: 'Poa' },
  { id: 9, name: 'Von' },
  { id: 10, name: 'Vision' },
];

export const mainCarousel = [
  {
    id: 1,
    name: 'Dry Iron',
    image: '/assets/images/slider1.jpeg',
    link: '/products/?slug=mika-dry-iron'
  },
  {
    id: 2,
    name: 'Ailyions',
    image: '/assets/images/slider2.jpeg',
    link: '/products/?slug=ailyons-gs0054-infrared-tabletop-gas-cooker'
  },
  {
    id: 5,
    name: 'Under Armour',
    image: '/assets/images/slider5.jpeg',
    link: '/products/?slug=samsung-galaxy-a25-5g65-256gb-8gb-ram-ram-dual-sim'
  },
  {
    id: 3,
    name: 'Mika',
    image: '/assets/images/slider3.jpeg',
    link: '/products/?slug=mika-smart-pressure-cooker-6l-ss-mpc106'
  },

  {
    id: 5,
    name: 'Under Armour',
    image: '/assets/images/slider5.jpeg',
    link: '/products/?slug=samsung-galaxy-a25-5g65-256gb-8gb-ram-ram-dual-sim'
  },
  {
    id: 6,
    name: 'Mika',
    image: '/assets/images/slider6.jpeg',
    link: '/searchQuery/?term=mika'
  },
  {
    id: 2,
    name: 'Ailyions',
    image: '/assets/images/slider2.jpeg',
    link: '/products/?slug=ailyons-gs0054-infrared-tabletop-gas-cooker'
  },
  {
    id: 1,
    name: 'Dry Iron',
    image: '/assets/images/slider1.jpeg',
    link: '/products/?slug=mika-dry-iron'
  },
];

export const bottomCarousel = [
  {
    id: 1,
    name: 'Brand 1',
    image: '/assets/bottom/filter1.jpeg',
    link: '/searchQuery/?term=blender'
  },
  {
    id: 2,
    name: 'Brand 2',
    image: '/assets/bottom/filter2.jpeg',
    link: '/searchQuery/?term=dispanser'
  },
  {
    id: 3,
    name: 'Brand 3',
    image: '/assets/bottom/filter3.jpeg',
    link: '/searchQuery/?term=kitchen%20appl'
  },
  {
    id: 4,
    name: 'Brand 4',
    image: '/assets/bottom/filter4.jpeg',
    link: '/searchQuery/?term=cooking'
  },
  {
    id: 5,
    name: 'Brand 5',
    image: '/assets/bottom/filter5.jpeg',
    link: '/searchQuery/?term=washing%20machine'
  },
  {
    id: 6,
    name: 'Brand 6',
    image: '/assets/bottom/filter6.jpeg',
    link: '/searchQuery/?term=laptop'
  },
  {
    id: 7,
    name: 'Brand 7',
    image: '/assets/bottom/filter7.jpeg',
    link: '/searchQuery/?term=televi'
  },
  {
    id: 8,
    name: 'Brand 8',
    image: '/assets/bottom/filter8.jpeg',
    link: '/searchQuery/?term=fridge'
  },
  {
    id: 9,
    name: 'Brand 9',
    image: '/assets/bottom/filter9.jpeg',
    link: '/searchQuery/?term=supermarket'
  },
  {
    id: 10,
    name: 'Brand 10',
    image: '/assets/bottom/filter10.jpeg',
    link: '/searchQuery/?term=out'
  },
  {
    id: 11,
    name: 'Brand 11',
    image: '/assets/bottom/filter11.jpeg',
    link: '/searchQuery/?term=health%20%26%20beauty'
  },
  {
    id: 12,
    name: 'Brand 12',
    image: '/assets/bottom/filter12.jpeg',
    link: '/searchQuery/?term=water%20tanks'
  },
];

export const topItems = [
  {
    id: 1,
    name: 'Top Item 1',
    image: '/assets/right/top3.jpeg',
    link: '/catalog'
  },
  {
    id: 2,
    name: 'Rot',
    image: '/assets/bottom/filter12.jpeg',
    link: '/searchQuery/?term=roto'
  },
  {
    id: 3,
    name: 'Roto',
    image: '/assets/right/top.jpeg',
    link: '/catalog'
  }
];

export const bottomItems = [
  {
    id: 4,
    name: 'Bottom Item 1',
    image: '/assets/right/top.jpeg',
    link: '/#'
  },
  {
    id: 5,
    name: 'Bottom Item 2',
    image: '/assets/right/top1.jpeg',
    link: '/#'
  },
  {
    id: 6,
    name: 'Dispenser',
    image: '/assets/bottom/filter2.jpeg',
    link: '/searchQuery/?term=dispenser'
  }
];






