// src/hooks/generateMetadata.js

import { fetchAllProducts } from "./fetchAllProducts";


export default async function generateProductsMetadata() {
    let products = null;

    try {
        products = await fetchAllProducts();
    } catch (error) {
        console.error("Error fetching all product data:", error);
    }

    // Handle case where products could not be fetched
    if (!products) {
        return {
            title: "Products Not Found",
            description: "No products available at this time.",
            keywords: ['products', 'not found', 'Cinab'],
            openGraph: {
                title: "Products Not Found - Cinab",
                description: "No products available at this time.",
                siteName: "Cinab",
                images: [
                    {
                        url: 'https://cinab.co.ke/images/default-image.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Products Not Found',
                    },
                ],
                locale: 'en_US',
                type: 'website',
            },
            twitter: {
                card: 'summary_large_image',
                title: "Products Not Found - Cinab",
                description: "No products available at this time.",
                images: ['https://cinab.co.ke/images/default-image.jpg'],
            },
        };
    }

    // Generate metadata for all products
    const metadataArray = products.map(product => ({
        title: `${product.name} - Buy Now`,
        description: product.description || "Explore this product on Cinab.",
        keywords: [product.name, 'buy', 'products', 'Cinab', 'ecommerce'],
        openGraph: {
            title: `${product.name} - Cinab`,
            description: product.description || "Explore this product on Cinab.",
            url: `https://cinab.co.ke/products/${product.slug}`, // Assuming slug exists
            siteName: "Cinab",
            images: [
                {
                    url: product.image || 'https://cinab.co.ke/images/default-image.jpg', // Fallback image
                    width: 800,
                    height: 600,
                    alt: product.name || 'Product Image',
                },
            ],
            locale: 'en_US',
            type: 'website',
        },
        twitter: {
            card: 'summary_large_image',
            title: `${product.name} - Cinab`,
            description: product.description || "Explore this product on Cinab.",
            images: [product.image || 'https://cinab.co.ke/images/default-image.jpg'], // Fallback image
        },
    }));

    return metadataArray; // Return array of metadata objects for all products
}
