import { createSlice } from "@reduxjs/toolkit";

// Utility function to calculate subTotal, total items, and discount
const calculateCartTotals = (cart) => {
  let totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);

  let discount = cart.reduce((acc, item) => {
    const itemDiscount = item.previousPrice > item.currentPrice
      ? (item.previousPrice - item.currentPrice) * item.quantity
      : 0;
    return acc + itemDiscount;
  }, 0);

  let subTotal = cart.reduce((acc, item) => acc + item.currentPrice * item.quantity, 0);

  let grandTotal = subTotal + discount

  return {
    subTotal,
    totalItems,
    discount,
    grandTotal,
  };
};


const initialState = {
  cart: [],
  wishlist: [],
  total: 0,
  subTotal: 0,
  grandTotal: 0,
  shipping: 0,
  tax: 0,
  discount: 0,
  serviceFee: 0,
  deliveryMethod: "",
  totalItems: 0,
  selectedDeliveryMethod: null,
  selectedCountyId: null,
  selectedPickupPoint: null,
  paymentMethod: "", // State for payment method
};


const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setSelectedDeliveryMethod: (state, action) => {
      state.selectedDeliveryMethod = action.payload
    },
    setSelectedCountyId: (state, action) => {
      state.selectedCountyId = action.payload
    },
    setSelectedPickupPoint: (state, action) => {
      state.selectedPickupPoint = action.payload
    },

    addToCart: (state, action) => {
      const existingItem = state.cart.find(item => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
      } else {
        state.cart.push({ ...action.payload, quantity: action.payload.quantity || 1 });
      }
      const { subTotal, totalItems, discount, grandTotal } = calculateCartTotals(state.cart);
      state.subTotal = subTotal;
      state.totalItems = totalItems;
      state.discount = discount;
      state.grandTotal = grandTotal;
      calculateTotal(state);
    },
    setCart: (state, action) => {
      state.cart = action.payload;
      const { subTotal, totalItems, discount, grandTotal } = calculateCartTotals(state.cart);
      console.log("disscount", discount);


      state.subTotal = subTotal;
      state.totalItems = totalItems;
      state.discount = discount;
      state.grandTotal = grandTotal
      calculateTotal(state); // Call the total calculation function
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item.id !== action.payload.id);
      const { subTotal, totalItems, discount, grandTotal } = calculateCartTotals(state.cart);
      state.subTotal = subTotal;
      state.totalItems = totalItems;
      state.discount = discount;
      state.grandTotal = grandTotal;
      calculateTotal(state); // Call the total calculation function
    },
    addToWishlist: (state, action) => {
      if (!state.wishlist.find(item => item.id === action.payload.id)) {
        state.wishlist.push(action.payload);
      }
    },
    removeFromWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(item => item.id !== action.payload.id);
    },
    setShipping: (state, action) => {
      state.shipping = action.payload.shipping;
      calculateTotal(state); // Call the total calculation function
    },
    applyDiscount: (state, action) => {
      state.discount = action.payload;
      calculateTotal(state); // Call the total calculation function
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      calculateTotal(state); // Call the total calculation function when payment method changes
    },
    setDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload
    },
    resetCart: (state) => {
      // Reset the cart state to initial values
      Object.assign(state, initialState);
    },
  },
});

// Utility function to calculate the total including service fee based on the payment method
const calculateTotal = (state) => {
  // Calculate total after discounts, shipping, and tax
  state.total = state.subTotal + parseInt(state.shipping, 10) + parseInt(state.tax, 10)

  // Calculate service fee only if payment method is "Stima Sacco Loan"
  if (state.paymentMethod === "stima") {
    state.serviceFee = state.total * 0.06; // Calculate service fee based on total
  } else {
    state.serviceFee = 0; // No service fee for other payment methods
  }

  state.total += state.serviceFee; // Add service fee to total
};

export const {
  addToCart,
  setCart,
  removeFromCart,
  addToWishlist,
  removeFromWishlist,
  setShipping,
  setDeliveryMethod,
  setSelectedCountyId,
  setSelectedDeliveryMethod,
  setSelectedPickupPoint,
  applyDiscount,
  setPaymentMethod,
  resetCart, // Export the resetCart action
} = cartSlice.actions;

export default cartSlice.reducer;

// Selectors
export const selectCart = (state) => state.cart.cart;
export const selectSelectedDeliveryMethod = (state) => state.cart.selectedDeliveryMethod;
export const selectSelectedCountyId = (state) => state.cart.selectedCountyId;
export const selectSelectedPickupPoint = (state) => state.cart.selectedPickupPoint;
export const selectDeliveryMethod = (state) => state.cart.deliveryMethod;
export const selectWishlist = (state) => state.cart.wishlist;
export const selectTotal = (state) => state.cart.total;
export const selectSubTotal = (state) => state.cart.grandTotal;
export const selectShipping = (state) => state.cart.shipping;
export const selectTax = (state) => state.cart.tax;
export const selectDiscount = (state) => state.cart.discount;
export const selectServiceFee = (state) => state.cart.serviceFee; // Selector for service fee
export const selectPaymentMethod = (state) => state.cart.paymentMethod; // Selector for payment method
export const selectTotalItems = (state) => state.cart.totalItems;
